
import { graphql } from 'gatsby'
import * as React from 'react'
import { extractFilterProductAttribute, extractFilterVariantAttribute } from '../../config/helper/reducers'
import { ProductsContainer } from '../components/specific/TemplateProducts/ProductsFiltered/Products.container'


/**
 * Template de génération des sous catégory avec infinite scroll et filtre 
 */

export const query = graphql`

query ProductsByCategoryQuery($categoryId: ID, $categoryIdString: String) {
  allStrapiCategorieContent(
    filter: {categorie: {saleor_id: {eq: $categoryIdString}}}
  ) {
    edges {
      node {
        description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        contenuSEO {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        titre_lien_bas_page
        contenu_liens_haut_de_page {
          name
          saleor_id
          slug
        }
        contenu_liens_bas_de_page {
          name
          saleor_id
          slug
        }
        faq {
          question {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          reponse {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        id
      }
    }
  }
  saleor {
    category(id: $categoryId) {
      name
      slug
      level
      id
      ...categoryChildFrag

    }
    products(
      first: 10000
      filter: {isPublished: true, categories: [$categoryId]}
      channel: "default-channel"
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...fragProductFilters
        }
      }
    }
  }
}

fragment fragProductFilters on SALEOR_Product {
  id
  name
  slug
  variants {
    attributes {
      attribute {
        name
        slug
      }
      values {
        name
        slug
      }
    }
  }
  attributes {
    values {
      name
      slug
    }
    values {
      name
      slug
    }
    attribute {
      name
      slug
    }
  }
}

`
function extractData(data) {
  let result = {}
  try {
    result = data.allStrapiCategorieContent.edges.map(({ node }) => {
      return {
        description: node.description?.data.childMarkdownRemark.html,
        contenuSEO:
          node.contenuSEO?.data.childMarkdownRemark.html,

        liens_haut_page: node.contenu_liens_haut_de_page?.map(
          ({ name, slug }) => ({ name, slug })
        ),
        liens_bas_page: node.contenu_liens_bas_de_page?.map(
          ({ name, slug }) => ({ name, slug })
        ),
        faq: node.faq?.map(({ question, reponse }) => ({
          question: question.data.childMarkdownRemark.html,
          reponse: reponse.data.childMarkdownRemark.html,
        })),
        titre_lien_bas_page: node.titre_lien_bas_page,
      }
    })[0]
  } catch (error) {
    console.warn("some data from CategoryContent are missing", error)
  }

  const { edges, pageInfo, totalCount } = data.saleor.products
  const products = edges?.map(({ node }) => node)
  result = { ...result, ...pageInfo, ...totalCount, products }

  return result as {
    description: string,
    contenuSEO: string,
    titre_lien_bas_page: string,
    liens_haut_page: any,
    liens_bas_page: any,
    faq: { question: string, reponse: string },

  }
}
const ProductsFromSubCategoryFilter = ({ pageContext, data }) => {
  // calcul de la liste d'id de productType a utiliser pour chercher les filtres
  let attributesValableFromProduct = data.saleor.products.edges.reduce(
    extractFilterProductAttribute,
    {}
  )
  let attributesValableFromVariant = data.saleor.products.edges.reduce(
    extractFilterVariantAttribute,
    {}
  )

  pageContext = {
    ...pageContext,
    ...extractData(data),
    ...{
      filters: {
        ...attributesValableFromProduct,
        ...attributesValableFromVariant,
      },
    },
  }


  const listOfSubCategories = data.saleor.category.children.edges.map(({ node }) => ({
    "slug": node.slug,
    "name": node.name,
    "backgroundImage": node.backgroundImage?.url
  }))

  pageContext.data = { ...pageContext.data, listOfSubCategories, category: data.saleor.category, productName: data.saleor.category.name }

  return <ProductsContainer pageContext={pageContext} data={data} />

}




export default ProductsFromSubCategoryFilter
